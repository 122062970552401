import React, { Component } from 'react'
import Imgloading from './list/itemloading';

export default class Cart extends Component {

    handleCartClose = close => {
        this.props.HandleCartClose(close);
    }

  render() {
    return (
        <div className={`cart${this.props.Show?' cart-show': ''}`}>
        <div className='cart-head'>
            <div className='cart-head-close'>
                <i className="fa-solid fa-xmark" onClick={()=> this.handleCartClose(false)}></i>
            </div>
        </div>
        <div className='cart-body'>

            {
                this.props.Cart.length?
                        <>
                            {
                                this.props.Cart.map( (item, index) => {
                                    return <div key={index} className='cart-item'>
                                                <div className='img'>
                                                    <Imgloading Img={item.info.image} />
                                                </div>
                                                <div className='info'>
                                                    <h3>
                                                        {
                                                            this.props.Language == "en" ? item.info.name_eng
                                                            : this.props.Language == "krd" ? item.info.name_krd
                                                            : item.info.name_ar
                                                        }
                                                    </h3>
                                                    <span>{ item.info.price.toLocaleString() }</span>
                                                </div>
                                                <div className='action'>
                                                    <i className="fa-solid fa-plus" onClick={() => this.props.updateCartItem(index, '+')}></i>
                                                    { item.qty.toLocaleString() }
                                                    <i className="fa-solid fa-minus" onClick={() => this.props.updateCartItem(index, '-')}></i>
                                                </div>
                                            </div>
                                })
                            }
                        </>
                        :
                        <p>There are no items in your list.</p>

                    
            }
        </div>

        <div className="cart-footer">
            <div className='info'>
                <div>Total: {this.props.SumPrice.toLocaleString()}</div>
                <div>Selected Item ({this.props.SumQty.toLocaleString()})</div>
            </div>
            <div className='btn' onClick={()=> { this.props.clearCartItem() }} >
                <i className="fa-solid fa-trash"></i>
                Clear
            </div>
        </div>
    </div>
    )
  }
}
